import React, { useState } from 'react';
import './Button.css';

function ConnectButton() {
  const [metaMaskConnected, setMetaMaskConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  const shortAddress = (address) => {
    return address ? address.substr(0, 6) + '...' + address.substr(-5) : '';
  };

  const connectMetamaskHandler = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });

        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        }

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });

        if (currentChainId !== '0x38') {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }],
            });
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: '0x38',
                      chainName: 'Binance Smart Chain',
                      rpcUrls: ['https://bsc-dataseed.binance.org/'],
                      nativeCurrency: {
                        name: 'BNB',
                        symbol: 'BNB',
                        decimals: 18,
                      },
                      blockExplorerUrls: ['https://bscscan.com/'],
                    },
                  ],
                });
              } catch (addError) {
                console.log(addError);
                return;
              }
            } else {
              console.log(switchError);
              return;
            }
          }
        }

        setMetaMaskConnected(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('Install MetaMask extension!');
    }
  };

  const disconnectWalletHandler = () => {
    if (window.ethereum) {
      try {
        if (typeof window.ethereum.disconnect === 'function') {
          window.ethereum.disconnect();
        }

        setMetaMaskConnected(false);
        setWalletAddress('');
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {metaMaskConnected ? (
        <button className="btn" onClick={disconnectWalletHandler}>
          {shortAddress(walletAddress)}
        </button>
      ) : (
        <button className="btn" onClick={connectMetamaskHandler}>
          Подключить MetaMask
        </button>
      )}
    </>
  );
}

export default ConnectButton;
