import ConnectButton from '../Buttons/ConnectButton';
import { useEffect, useState } from 'react';
import tokenAbi from '../../ABI/tokenABI.json';
import axios from 'axios';
import { ethers } from 'ethers';
import './Header.css';

const Header = () => {
  const [emivnBalance, setEmivnBalance] = useState('Загрузка...');
  const [usdBalance, setUsdBalance] = useState('Загрузка...');
  const [loading, setLoading] = useState(true);

  const stakingAddress = process.env.REACT_APP_STAKING_ADDRESS;
  const adminAddress = process.env.REACT_APP_ADMIN_ADDRESS;
  const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_PROVIDER,
  );
  const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);

  const checkEmivnBalance = async () => {
    try {
      const contractTokenBalance = (
        await tokenContract.balanceOf(stakingAddress)
      ).toString();
      const frontendBalance = contractTokenBalance / 10 ** 18;
      setEmivnBalance(frontendBalance);
    } catch (error) {
      console.error('Ошибка отображения баланса смарт контракта:', error);
      setEmivnBalance('Ошибка');
    } finally {
      setLoading(false);
    }
  };

  const checkUsdBalance = async () => {
    try {
      const bnbBalance = await provider.getBalance(adminAddress);
      const frontendBalance = bnbBalance / 10 ** 18;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BALANCE_API}/simple/price?ids=binancecoin&vs_currencies=usd`,
        );
        const bnbUsdPrice = response.data.binancecoin.usd;
        const usdAdminBalance = (frontendBalance * bnbUsdPrice).toFixed(2);
        setUsdBalance(usdAdminBalance);
      } catch (error) {
        console.error('Ошибка получения цены BNB:', error);
      }
    } catch (error) {
      console.error('Ошибка отображения баланса адреса:', error);
      setUsdBalance('Ошибка');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkEmivnBalance();
    checkUsdBalance();
  }, []);

  return (
    <header>
      <div className="header-button">
        <ConnectButton />
      </div>
      <p className="total-payout">
        Админ 0x3A55811A1F200D5A418156072BCbbfb8d82b989d
      </p>
      <div className="total-payout">
        {loading
          ? 'Загрузка...'
          : `Баланс смарт контракта стейкинга --- ${emivnBalance} EMIVN`}
      </div>
      <div className="total-payout">
        {loading
          ? 'Загрузка...'
          : `Баланс кошелька 0x9804Ce...5aDbfA --- ${usdBalance} USD`}
      </div>
    </header>
  );
};

export default Header;
