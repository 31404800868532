import TransactionsList from '../TransactionsList/TransactionsList';
import SendTokens from '../SendTokens/SendTokens';
import { useState } from 'react';

const Main = () => {
  const [lastSend, setLastSend] = useState(null);

  return (
    <main>
      <SendTokens setLastSend={setLastSend} />
      <TransactionsList lastSend={lastSend} />
    </main>
  );
};

export default Main;
