import './TransactionsList.css';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const TransactionsList = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchWalletAddress, setSearchWalletAddress] = useState('');

  const getTransactionsHandler = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + 'stake');
      setTransactions(response.data);
    } catch (error) {
      console.error('Ошибка отображения стейков:', error);
      alert('Ошибка отображения стейков');
    }
  }, []);

  useEffect(() => {
    getTransactionsHandler();
  }, [getTransactionsHandler]);

  const formatLockTime = (timestamp) => {
    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];
    const date = new Date(parseInt(timestamp, 10));
    date.setDate(date.getDate() + 1);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const handleSearchChange = (event) => {
    setSearchWalletAddress(event.target.value);
  };

  const filteredTransactions = transactions.filter((tx) =>
    tx.wallet_address.includes(searchWalletAddress),
  );

  const sortedTransactions = filteredTransactions.sort(
    (a, b) => parseInt(a.lock_time, 10) - parseInt(b.lock_time, 10),
  );

  return (
    <div className="transactions_container">
      <div className="search_container">
        <input
          type="text"
          value={searchWalletAddress}
          onChange={handleSearchChange}
          placeholder="Поиск по адресу кошелька"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>№</th>
            <th>Адрес кошелька</th>
            <th>Кол-во токенов</th>
            <th>Вывод из стейкинга</th>
            <th className="button_column">Транзакция</th>
          </tr>
        </thead>
        <tbody>
          {sortedTransactions.map((tx, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <a
                    href={
                      process.env.REACT_APP_BSCSCAN_ADDRESS + tx.wallet_address
                    }
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    {tx.wallet_address}
                  </a>
                </td>
                <td>{tx.token_amount}</td>
                <td>{formatLockTime(tx.lock_time)}</td>
                <td>
                  <a
                    href={process.env.REACT_APP_BSCSCAN_TX + tx.hash}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'btn'}
                  >
                    Ссылка на транзакцию
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsList;
