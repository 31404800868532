import { ethers, BigNumber } from 'ethers';
import { useState } from 'react';
import axios from 'axios';
import './SendTokens.css';
import tokenAbi from '../../ABI/tokenABI.json';
import stakingAbi from '../../ABI/stakingAbi.json';

const terms = [90, 180, 360, 720];

const stakingAddress = '0x6d7A7D79b7C784a88b73259CCf889cd3A26DE7C5'; //process.env.REACT_APP_STAKING_ADDRESS;

const stakingContract = () => {
  const contractAddress = '0x6d7A7D79b7C784a88b73259CCf889cd3A26DE7C5'; //process.env.REACT_APP_CONTRACT_ADDRESS;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const stakingContract = new ethers.Contract(
    contractAddress,
    stakingAbi,
    signer,
  );

  return stakingContract;
};

const tokenContract = () => {
  const contractAddress = '0x6966b2a543E34E69Cf1c7840137FfD2d8a09e834'; //process.env.REACT_APP_TOKEN_ADDRESS;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const tokenContract = new ethers.Contract(contractAddress, tokenAbi, signer);

  return tokenContract;
};

const approveTokens = async () => {
  try {
    const tcont = tokenContract();
    const tx = await tcont.approve(
      stakingAddress,
      ethers.utils.parseUnits('10000000000000000', 9),
      {
        gasLimit: 2000000,
      },
    );
    await tx.wait();
    alert('Апрув успешно произведен');
  } catch (e) {
    console.log(e.message);
    alert('Ошибка при апруве');
  }
};

const SendTokens = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [tokensCount, setTokensCount] = useState('');
  const [term, setTerm] = useState(terms[0]);

  const handleChangeAddress = (event) => {
    setWalletAddress(event.target.value);
  };

  const changeTokensCountHandler = (num) => {
    const numberValue = num;
    const number = +numberValue;
    if (numberValue === '' || !isNaN(number)) {
      if (number < 0) {
        setTokensCount(0);
      } else {
        setTokensCount(number);
      }
    }
  };

  const changeTermHandler = (term) => {
    setTerm(+term);
  };

  const sendHandler = async () => {
    let receipt;
    let txHash;
    if (
      !walletAddress ||
      walletAddress === '' ||
      !ethers.utils.isAddress(walletAddress)
    ) {
      alert('Неправильный адрес');
    }
    try {
      const decimals = BigNumber.from('1000000000000000000');
      const scont = stakingContract();

      const tx = await scont.stake(
        walletAddress,
        BigNumber.from(tokensCount).mul(decimals).toBigInt(),
        term,
        {
          gasLimit: 1000000,
        },
      );
      receipt = await tx.wait();
      txHash = tx.hash;
    } catch (e) {
      console.log(e.message);
      alert('Ошибка в отправке транзакции');
    }
    if (receipt && receipt.status === 1) {
      const scont = stakingContract();
      let stakeNumber = await scont.getMyLastStakedId(walletAddress);
      let stakeNumberIndex = stakeNumber - 1;
      try {
        const currentDate = new Date();
        const time = new Date(
          currentDate.getTime() + term * 24 * 60 * 60 * 1000,
        );
        const unixTimestamp = time.getTime();
        const transactionData = {
          wallet_address: walletAddress,
          token_amount: tokensCount,
          lock_time: unixTimestamp,
          hash: txHash,
          stake_id: `${walletAddress}${stakeNumberIndex.toString()}`,
        };
        await axios.post(
          process.env.REACT_APP_API_URL + 'stake',
          transactionData,
        );
        window.location.reload();
      } catch (error) {
        alert('Ошибка в базе данных');
        console.error('Ошибка в базе данных:', error);
      }
    }
  };

  return (
    <div className="send_tokens_container">
      <h3>ОТПРАВИТЬ ТОКЕНЫ</h3>
      <div className="inputs_container">
        <input
          type={'text'}
          placeholder={'Адрес кошелька'}
          value={walletAddress}
          onChange={handleChangeAddress}
        ></input>
        <input
          type={'text'}
          placeholder={'Введите количество токенов'}
          value={tokensCount}
          onChange={(e) => changeTokensCountHandler(e.target.value)}
        ></input>
        <select onChange={(e) => changeTermHandler(e.target.value)}>
          {terms.map((term) => {
            return (
              <option value={term} key={term}>
                {term} дней
              </option>
            );
          })}
        </select>
      </div>
      <div className="buttons_container">
        <button className="btn transparent" onClick={approveTokens}>
          Апрув для смарт контракта стейкинга
        </button>
        <button className="btn" onClick={sendHandler}>
          Отправить
        </button>
      </div>
    </div>
  );
};

export default SendTokens;
